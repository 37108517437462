





























import { Component, Vue } from 'vue-property-decorator'
import { inject } from 'inversify-props'
import { CaseInterviewSharesClient, Interview } from 'ritem-common'
import InterviewSuccessMessage from '@/components/InterviewSuccessMessage.vue'

@Component({ components: { Interview, InterviewSuccessMessage } })
export default class SharedInterview extends Vue {
  @inject()
  caseInterviewSharesClient: CaseInterviewSharesClient

  interviewData: string = ''
  shareId = ''
  notFound = false

  completedSurvey = false
  loading = false
  success = false

  lastResult = null

  async created() {
    console.log('created onboarding')
    this.shareId = this.$route.params.id
    await this.load(this.shareId)
  }

  async load(id: string) {
    try {
      const result =
        await this.caseInterviewSharesClient.getSharedInterviewData(id)
      this.interviewData = JSON.stringify(result)
    } catch (e) {
      this.notFound = true
    }
  }

  async completed(result) {
    this.lastResult = result
    this.completedSurvey = true
    try {
      this.loading = true
      await this.caseInterviewSharesClient.completeSharedInterview(
        this.shareId,
        JSON.stringify(result)
      )
      this.success = true
    } catch (e) {
      this.success = false
    }
    this.loading = false
  }

  async retry() {
    await this.completed(this.lastResult)
  }

  downloadResult() {
    const a = document.createElement('a')
    const filename = `interview_result_${Date.now()}.json`
    const dataURI =
      'data:application/json;base64,' + btoa(JSON.stringify(this.lastResult))
    a.href = dataURI
    a.download = filename
    const e = document.createEvent('MouseEvents')

    e.initMouseEvent(
      'click',
      true,
      false,
      document.defaultView,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )
    a.dispatchEvent(e)
    a.remove()
  }

  applyCssClasses(defaultBootstrapCss: any) {
    defaultBootstrapCss.matrixdynamic.buttonAdd = 'btn btn-secondary'
    defaultBootstrapCss.matrixdynamic.buttonRemove = 'btn btn-primary'
    defaultBootstrapCss.navigation.complete = 'btn sv_complete_btn btn-primary'
    defaultBootstrapCss.navigation.prev = 'btn sv_prev_btn btn-secondary'
    defaultBootstrapCss.navigation.next = 'btn sv_next_btn btn-secondary'
    defaultBootstrapCss.navigation.start = 'btn sv_start_btn btn-secondary'
    defaultBootstrapCss.navigation.preview = 'btn sv_preview_btn btn-secondary'
    defaultBootstrapCss.navigation.edit = 'btn sv_edit_btn btn-secondary'
    defaultBootstrapCss.rating.selected = 'active ratingactive'
    defaultBootstrapCss.signaturepad.clearButton =
      'sjs_sp_clear btn btn-secondary'
    defaultBootstrapCss.file.removeButton =
      'sv_q_file_remove_button btn btn-primary'
    defaultBootstrapCss.paneldynamic.buttonAdd =
      'sv-paneldynamic__add-btn btn btn-secondary'
    defaultBootstrapCss.paneldynamic.buttonRemove =
      'sv_p_remove_btn btn btn-primary'
  }
}
